export const TEST_IDS = {
  HEADER: 'header',
  CITY_LINK: 'cityLink',
  SEE_ALL_LINK: 'seeAllLink'
}

export const mockTopCities = [
  {
    id: 12315,
    slug: 'stockton-ca',
    title: 'Weed Delivery In Stockton, CA',
    content: '',
    state_long: 'California',
    city: 'Stockton',
    location: {
      city: 'Stockton',
      state_short: 'CA',
      state_long: 'California',
      region: 'San Joaquin County',
      zipcode: '95219'
    },
    yoast_metadata: {
      focuskw: 'cannabis delivery Stockton',
      title: 'Cannabis Delivery In Stockton, CA',
      keywordsynonyms: '[""]',
      focuskeywords: '[{"keyword":"","score":""}]',
      linkdex: '28',
      'estimated-reading-time-minutes': '0',
      wordproof_timestamp: ''
    }
  },
  {
    id: 12301,
    slug: 'anaheim-ca',
    title: 'Weed Delivery In Anaheim, CA',
    content: '',
    state_long: 'California',
    city: 'Anaheim',
    location: {
      city: 'Anaheim',
      state_short: 'CA',
      state_long: 'California',
      region: 'Orange County',
      zipcode: '92805'
    },
    yoast_metadata: {
      focuskw: 'cannabis delivery Anaheim',
      title: 'Cannabis Delivery In Anaheim, CA',
      keywordsynonyms: '[""]',
      focuskeywords: '[{"keyword":"","score":""}]',
      linkdex: '28',
      'estimated-reading-time-minutes': '0',
      wordproof_timestamp: ''
    }
  }
]
