import { createSelector } from 'reselect'

import { getPreviousLocations } from '../location/selectors'
import { RootState } from '../reducers'

const getAddressModalState = (state: RootState) => state.addressModal

const getAddressSuggestions = createSelector([getAddressModalState], (addressModal) => addressModal.suggestions)

export const getDisplayedSuggestions = createSelector(
  [getAddressSuggestions, getPreviousLocations],
  (suggestions, previousLocations) => {
    // if we don't have any suggestions, but we do have previousLocations, let's use those
    const usePreviousLocation =
      Array.isArray(suggestions) && Array.isArray(previousLocations) && !suggestions.length && previousLocations.length
    return usePreviousLocation ? previousLocations : suggestions
  }
)

export const getResidentialOnlyError = createSelector(
  [getAddressModalState],
  (addressModal) => addressModal.residentialOnlyError
)
